import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import PreviewCompatibleImage from './PreviewCompatibleImage'
import { SubHeader } from './common/Typography'
import './AppIcon.scss'

type AppIconProps = {
  image: any
  appName: string
  isIos: boolean
  isAndroid: boolean
}

export const AppIcon = ({ image, appName, isIos, isAndroid }: AppIconProps) => {
  return (
    <div className="AppIcon">
      <div className="AppIconImage">
        <PreviewCompatibleImage
          imageInfo={{
            image,
            alt: `${appName} icon.`,
          }}
          style={{
            borderRadius: 15,
          }}
        />
      </div>
      <SubHeader>{appName}</SubHeader>
      <div className="AppPlatforms">
        {isIos && (
          <StaticImage
            className="AppPlatform"
            src="../img/appstores/apple.svg"
            alt="Apple"
            placeholder="tracedSVG"
            width={25}
          />
        )}
        {isAndroid && (
          <StaticImage
            className="AppPlatform"
            src="../img/appstores/android.svg"
            alt="Android"
            placeholder="tracedSVG"
            width={25}
          />
        )}
      </div>
    </div>
  )
}
