import React, { useEffect, useRef } from 'react'

const VISIBLE_FOR_MS = 1000

export function AnalyticsAnchor({ id }: { id: string }) {
  const anchorRef = useRef<any>()
  const analyticsSent = useRef(false)

  useEffect(() => {
    let timer: number | null = null

    function checkScrollPosition() {
      try {
        const anchorScrollY = anchorRef.current.getBoundingClientRect().top
        if (
          !analyticsSent.current &&
          anchorScrollY > 0 &&
          anchorScrollY < window.innerHeight
        ) {
          if (!timer) {
            timer = window.setTimeout(() => {
              const nextAnchorScrollY =
                anchorRef.current.getBoundingClientRect().top
              if (
                nextAnchorScrollY > 0 &&
                nextAnchorScrollY < window.innerHeight
              ) {
                sendAnalyticsEvent()
              }
              if (timer) {
                window.clearTimeout(timer)
              }
              timer = null
            }, VISIBLE_FOR_MS)
          }
        }
      } catch (error) {}
    }

    function sendAnalyticsEvent() {
      try {
        if (window.plausible) {
          window.plausible('AnalyticsAnchor', {
            props: { id },
          })
        }
        analyticsSent.current = true
        document.removeEventListener('scroll', checkScrollPosition)
      } catch (error) {}
    }

    if (anchorRef.current) {
      document.addEventListener('scroll', checkScrollPosition)
    }
    return () => {
      if (timer) {
        window.clearTimeout(timer)
        timer = null
      }
      document.removeEventListener('scroll', checkScrollPosition)
    }
  }, [])
  return <div style={{ display: 'hidden' }} ref={anchorRef} />
}
