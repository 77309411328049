import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import './AppStoreLinks.scss'

type AppStoreLinksProps = {
  appStoreLink: string
  playStoreLink: string
}

export const AppStoreLinks = ({
  appStoreLink,
  playStoreLink,
}: AppStoreLinksProps) => {
  return (
    <div className="AppStoreLinks">
      <a href={appStoreLink} target="_blank" rel="noopener">
        <StaticImage
          src="../../static/img/appstore_apple.png"
          alt="Apple App Store"
          layout="fixed"
          height={50}
        />
      </a>
      <a href={playStoreLink} target="_blank" rel="noopener">
        <StaticImage
          src="../../static/img/appstore_google.png"
          alt="Google Play Store"
          layout="fixed"
          height={50}
        />
      </a>
    </div>
  )
}
