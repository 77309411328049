import React from 'react'

import './WhoAreWe.scss'
import longCompanyImage from '../../../static/img/companies-long.png'
import wideCompanyImage from '../../../static/img/companies-wide.png'

import { MegaHeader, PageHeader, Text } from '../common/Typography'
import { AnalyticsAnchor } from '../../components/AnalyticsAnchor'
import { SuperHeroDisplay } from '../SuperHeroDisplay'

interface WhoAreWeProps {
  title: string
  subtitle: string
  chrisImage: any
  chrisTitle: string
  chrisBlurb: string
  chrisLinkedInProfileUrl: string
  russImage: any
  russTitle: string
  russBlurb: string
  russLinkedInProfileUrl: string
  workedForTitle: string
  workedForSubtitle: string
}

export const WhoAreWe = ({
  title,
  subtitle,
  chrisImage,
  chrisTitle,
  chrisBlurb,
  chrisLinkedInProfileUrl,
  russImage,
  russTitle,
  russBlurb,
  russLinkedInProfileUrl,
  workedForTitle,
  workedForSubtitle,
}: WhoAreWeProps) => {
  return (
    <div
      className="WhoAreWe"
      style={{
        margin: 'auto',
      }}
    >
      <section className="section">
        <div className="content">
          <div
            className="is-flex-tablet"
            style={{
              minHeight: '80vh',
            }}
          >
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                flex: 1,
              }}
            >
              <div
                className="heading-container"
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <MegaHeader as="h2">{title}</MegaHeader>
                <Text>{subtitle}</Text>
                <AnalyticsAnchor id="MeetTheTeam" />
              </div>
              <div className="super-hero-container">
                <SuperHeroDisplay
                  name={russTitle}
                  blurb={russBlurb}
                  image={russImage}
                  linkUrl={russLinkedInProfileUrl}
                  linkTitle="View LinkedIn profile"
                />
                <SuperHeroDisplay
                  name={chrisTitle}
                  blurb={chrisBlurb}
                  image={chrisImage}
                  linkUrl={chrisLinkedInProfileUrl}
                  linkTitle="View LinkedIn profile"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-two">
        <div className="content">
          <div
            className="worked-for-container"
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <div className="worked-for-header-container">
              <PageHeader as="h3">{workedForTitle}</PageHeader>
              <AnalyticsAnchor id="WorkedFor" />
              <div className="worked-for-header-container-subtitle">
                <Text>{workedForSubtitle}</Text>
              </div>
            </div>
            <div className="wide-company-logos">
              <img
                src={wideCompanyImage}
                alt="Logos of companies that we have worked with, including Fulby, Mettle, Dixons Carphone and Nandos"
              />
            </div>
            <div className="long-company-logos">
              <img
                src={longCompanyImage}
                alt="Logos of companies that we have worked with, including Fulby, Mettle, Dixons Carphone and Nandos"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
