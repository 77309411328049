import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../../components/Layout'
import { BetterDrivingTheory } from '../../../components/page-components/BetterDrivingTheory'

const BetterDrivingTheoryPage = ({ data }) => {
  const {
    app: { frontmatter },
  } = data
  return (
    <Layout
      title={frontmatter.title}
      description={frontmatter.description}
      ogImageName="og_image_betterdriving.png"
    >
      <BetterDrivingTheory
        title={frontmatter.title}
        description={frontmatter.description}
        screenshots={frontmatter.screenshots}
        betterFeatures={frontmatter.betterFeatures}
        smallPrint={frontmatter.smallPrint}
        termsLink={frontmatter.termsLink}
        privacyLink={frontmatter.privacyLink}
      />
    </Layout>
  )
}

export default BetterDrivingTheoryPage

export const pageQuery = graphql`
  query BetterDrivingTheoryPageTemplate {
    app: markdownRemark(frontmatter: { key: { eq: "better-driving-theory" } }) {
      frontmatter {
        title
        description
        screenshots {
          id
          image {
            alt
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 300
                  quality: 100
                  placeholder: NONE
                )
              }
            }
          }
        }
        betterFeatures
        smallPrint
        termsLink
        privacyLink
      }
    }
  }
`
