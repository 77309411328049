import React from 'react'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'
import clsx from 'clsx'

import '../theme/styles.scss'

// import Footer from './Footer'
// import Navbar from './Navbar'
import useSiteMetadata from './SiteMetadata'
import { IS_NAVBAR_FIXED_TOP } from '../config'

const plausibleScript = `window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }`

interface LayoutProps {
  title?: string
  description?: string
  ogImageName?: string
  children: JSX.Element
}

const Layout = ({ title, description, ogImageName, children }: LayoutProps) => {
  const {
    name,
    title: defaultTitle,
    description: defaultDescription,
  } = useSiteMetadata()

  const containerClassName = clsx({
    'has-navbar-fixed-top': IS_NAVBAR_FIXED_TOP,
  })

  return (
    <div>
      <Helmet titleTemplate={`${name} | %s`}>
        <html lang="en" className={containerClassName} />
        <title>{title || defaultTitle}</title>
        <meta name="description" content={description || defaultDescription} />

        <meta property="og:title" content={title} />
        <meta
          property="og:description"
          content={description || defaultDescription}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/${ogImageName || 'og_image.png'}`}
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="325" />
        <meta property="og:image:height" content="175" />

        {/* Simple site analytics */}
        <script
          async
          defer
          data-domain="72days.dev"
          src="https://plausible.io/js/plausible.outbound-links.js"
        ></script>
        <script>{plausibleScript}</script>
      </Helmet>
      <div>{children}</div>
      {/* <Footer /> */}
    </div>
  )
}

export default Layout
