import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import { Movember } from '../../components/page-components/Movember'

export default ({ data }) => {
  const { movember } = data
  return (
    <Layout>
      <Movember {...movember.frontmatter} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query Movember2021PageTemplate {
    movember: markdownRemark(frontmatter: { key: { eq: "movember-2021" } }) {
      frontmatter {
        pageTitle
        pageIntro
        members {
          name
          message
          imageChar {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 200
                quality: 100
                placeholder: TRACED_SVG
              )
            }
          }
          imageReal {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 200, quality: 100)
            }
          }
        }
        qrCodeImage {
          childImageSharp {
            gatsbyImageData(
              layout: FIXED
              width: 200
              height: 200
              quality: 100
            )
          }
        }
        donationLink
        donationTitle
        donationSubTitle
      }
    }
  }
`
