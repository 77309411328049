import React, { useMemo } from 'react'
import sortBy from 'lodash.sortby'

import { SwipeableSlides } from './common/SwipeableSlides'
import { Text, TitleSmall } from './common/Typography'

import './Testimonials.scss'

export interface TestimonialItem {
  id: string
  priority: number
  name: string
  title: string
  comments: string
}

export const FakeTestimonial: TestimonialItem = {
  id: 'fake',
  priority: 1,
  name: 'Test',
  title: 'Test @ test.com',
  comments:
    'testtesttesttest testtesttesttest testtesttesttest testtesttesttest testtesttesttest',
}

interface TestimonialsProps {
  testimonials: Array<TestimonialItem>
  initialIndex?: number
}

export const TestimonialListItem = ({
  name,
  title,
  comments,
}: TestimonialItem & { isActive?: boolean }) => {
  return (
    <div className="TestimonialListItem">
      <Text>{comments}</Text>
      <TitleSmall className="pt-2">
        {name} - {title}
      </TitleSmall>
    </div>
  )
}

export const Testimonials = ({
  initialIndex = 0,
  testimonials,
}: TestimonialsProps) => {
  const testimonialsSorted = useMemo(() => {
    return sortBy<TestimonialItem>(testimonials, ['priority']).reverse()
  }, [testimonials])
  return (
    <div className="Testimonials">
      <SwipeableSlides
        initialIndex={initialIndex}
        children={testimonialsSorted.map((item) => (
          <TestimonialListItem key={item.id} {...item} />
        ))}
      />
    </div>
  )
}
