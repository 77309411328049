import React from 'react'
import clsx from 'clsx'
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin'
import { FaEnvelope } from '@react-icons/all-files/fa/FaEnvelope'
import { FaClock } from '@react-icons/all-files/fa/FaClock'
import { FaPaperPlane } from '@react-icons/all-files/fa/FaPaperPlane'

import './Button.scss'

export const Button: React.FC<{
  className?: string
  title: string
  onPress?: () => void
  type?: 'primary' | 'secondary'
  outline?: boolean
  size?: 'small' | 'medium' | 'large'
  isSubmit?: boolean
  isDisabled?: boolean
  fullWidthMobile?: boolean
  cta?: 'linkedIn' | 'email' | 'calendly' | 'send'
}> = ({
  className = '',
  title,
  type = 'primary',
  outline,
  size,
  onPress,
  isSubmit = false,
  isDisabled = false,
  fullWidthMobile = false,
  cta,
}) => {
  const classNames = clsx({
    [className]: true,
    Button: true,
    button: true,
    'is-primary': type === 'primary',
    'is-outlined': outline,
    'is-small': size === 'small',
    'is-medium': size === 'large',
    'full-width-mobile': fullWidthMobile,
    'cta-linked-in': cta === 'linkedIn',
  })

  const icon = cta ? (
    <span className="icon">
      {cta === 'linkedIn' ? (
        <FaLinkedin />
      ) : cta === 'email' ? (
        <FaEnvelope />
      ) : cta === 'calendly' ? (
        <FaClock />
      ) : (
        <FaPaperPlane />
      )}
    </span>
  ) : null

  return (
    <button
      className={classNames}
      onClick={onPress}
      type={isSubmit ? 'submit' : 'button'}
      disabled={isDisabled}
    >
      {icon}
      <span>{title}</span>
    </button>
  )
}
