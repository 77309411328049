import React from 'react'
// import { Link } from 'gatsby'
import clsx from 'clsx'

// import SocialButtons from './SocialButtons'
// import { navLinks } from '../config'
import useSiteMetadata from './SiteMetadata'
import './Footer.scss'

const Footer = ({
  docked,
  leftAlign,
}: {
  docked?: boolean
  leftAlign?: boolean
}) => {
  const {
    name,
    copyrightYear,
    // contactEmail
    companyNumInfo,
  } = useSiteMetadata()

  const containerClassName = clsx({
    'SeventyTwoDays-footer': true,
    docked: docked,
    'left-align': leftAlign,
  })

  const contentClassName = clsx({
    content: true,
    'is-small': true,
    'has-text-centered': !leftAlign,
  })

  return (
    <footer className={containerClassName}>
      <div className="container">
        {/* <div style={{ maxWidth: '100vw' }} className="columns">
          <div className="column is-4">
            <section className="menu">
              <ul className="menu-list">
                <li>
                  <Link to="/" className="navbar-item">
                    Home
                  </Link>
                </li>
                {navLinks.map((link) => (
                  <li key={link.path}>
                    <Link className="navbar-item" to={link.path}>
                      {link.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </section>
          </div>
          <div className="column is-4" />
          <div className="column is-4">
            <SocialButtons />
            <section>
              <a className="link" href={`mailto:${contactEmail}`}>
                {contactEmail}
              </a>
            </section>
          </div>
        </div> */}
        <div className={contentClassName}>
          <p>
            © {copyrightYear} - {name} - {companyNumInfo}
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
