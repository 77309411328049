import {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useRef,
  useState,
} from 'react'
import { navigate } from 'gatsby'

export const useContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const [formData, setFormData] = useState({})
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false)

  const formDataRef = useRef(formData)
  formDataRef.current = formData

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault()
      if (disclaimerAccepted && !isSubmitting) {
        setIsSubmitting(true)
        setError(null)
        const form = e.target as HTMLFormElement
        try {
          const resp = await fetch('/.netlify/functions/contactRequest', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams(formData).toString(),
          })

          const text = await resp.text()
          if (resp.ok && text === 'OK') {
            const action = form.getAttribute('action')
            if (action) {
              navigate(action)
            }
          } else {
            if (text.indexOf('<html>') === -1) {
              throw new Error(text)
            } else {
              throw new Error('Server not responding.')
            }
          }
        } catch (err) {
          setError(err)
        }
        setIsSubmitting(false)
      }
    },
    [disclaimerAccepted, isSubmitting, setIsSubmitting, formData]
  )

  const onChangeField = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setFormData({
        ...formDataRef.current,
        [e.target.name]: e.target.value,
      })
    },
    [formDataRef, setFormData]
  )

  const onClearError = useCallback(() => {
    setError(null)
  }, [setError])

  const onToggleDisclaimer = useCallback(() => {
    setDisclaimerAccepted((curr) => !curr)
  }, [])

  return {
    disclaimerAccepted,
    isSubmitting,
    error,
    onChangeField,
    onSubmit,
    onClearError,
    onToggleDisclaimer,
  }
}
