import React from 'react'
import { navigate } from 'gatsby'

import './OurWork.scss'

import { AnalyticsAnchor } from '../../components/AnalyticsAnchor'
import { AppIcon } from '../AppIcon'
import { MegaHeader, Text } from '../common/Typography'

interface App {
  appName: string
  appIconImg: any
  pageLink: string
  isLinkExternal: boolean
  description: string
  isAndroid: boolean
  isIos: boolean
}

interface OurWorkProps {
  title: string
  subtitle: string
  apps: App[]
}

export const OurWork = ({ title, subtitle, apps }: OurWorkProps) => {
  return (
    <div className="OurWork">
      <section className="section">
        <div className="content">
          <div className="has-text-centered">
            <MegaHeader as="h2">{title}</MegaHeader>
            <Text>{subtitle}</Text>
          </div>
        </div>
        <AnalyticsAnchor id="OurWork" />

        <div className="content">
          <div className="Apps columns is-3">
            {apps.map((app) => {
              return (
                <div className="column is-half">
                  <div
                    className="AppItem"
                    onClick={() => {
                      if (app.isLinkExternal) {
                        window.open(app.pageLink, '_blank')
                      } else {
                        navigate(app.pageLink)
                      }
                    }}
                  >
                    <AppIcon
                      appName={app.appName}
                      image={app.appIconImg}
                      isAndroid={app.isAndroid}
                      isIos={app.isIos}
                    />
                    <div>
                      <Text>{app.description}</Text>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <AnalyticsAnchor id="OurWork" />
      </section>
    </div>
  )
}
