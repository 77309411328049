import React from 'react'

import Footer from '../../components/Footer'
import {
  MegaHeader,
  SubHeader,
  Label,
  Text,
} from '../../components/common/Typography'

type PolicySection = {
  title: string
  content: {
    title?: string
    text?: string
    list?: string[]
    footnote?: string
  }[]
}
type PolicySections = PolicySection[]

// policy content based on the below template from https://gdpr.eu/privacy-notice:
// https://gdpr.eu/wp-content/uploads/2019/01/Our-Company-Privacy-Policy.pdf

const policySections: PolicySections = [
  {
    title: 'What Data Do We Collect?',
    content: [
      {
        title: 'We collect the following data',
        list: ['Name', 'Email Address', 'Other voluntarily provided data'],
      },
    ],
  },
  {
    title: 'How do we collect your data?',
    content: [
      {
        title: 'We collect and process data when you',
        list: [
          'Request an introduction using the form on our website',
          'Schedule a free consultation with us via the Calendly website link',
        ],
      },
    ],
  },
  {
    title: 'How will we use your data?',
    content: [
      {
        title:
          'We collect your name, email address and other voluntarily provided data so that we can',
        list: [
          'Contact you directly in order to discuss if and how we can be of service',
        ],
      },
    ],
  },
  {
    title: 'How do we store your data?',
    content: [
      {
        text: 'Your name and email address and any other additional information you provide are sent via email to a 72days.dev email inbox',
      },
    ],
  },
  {
    title: 'Marketing',
    content: [
      {
        text: 'We will not use the name and email address captured via the request an introduction form for any of our marketing activities',
      },
    ],
  },
  {
    title: 'What are your data protection rights?',
    content: [
      {
        title:
          'Everyone who uses our request an introduction facility is entitled to',
        list: [
          'The right to access - you have the right to request that 72 Days provides you with copies of the personal data that we hold for you',
          'The right to rectification - you have the right to request that 72 Days corrects any personal data we hold for you that you believe is inaccurate or complete any information that you believe is incomplete',
          'The right to erasure - you have the right to request that 72 Days erases all of the personal data that we hold for you',
          'The right to restrict processing - you have the right to request that 72 Days restricts the processing of the personal data that we hold for you',
          'The right to object to processing - you have the right to request that 72 Days does not process any of the personal data that we hold for you',
          'The right to data portability - you have the right to request that 72 Days transfers the personal data that we hold for you to another organisation or directly to you',
        ],
        footnote:
          'If you make a request, we have one month to respond to you. If you would like to exercise any of these rights please contact us via email at hello@7days.dev',
      },
    ],
  },
  {
    title: 'Cookies',
    content: [
      {
        text: 'We do not create or store any cookies when you use our website',
      },
    ],
  },
  {
    title: 'Privacy Policies of Other Websites',
    content: [
      {
        text: 'The 72 Days website contains links to other websites. Our privacy policy applies only to our website. You should read the privacy policy of any website that we link to, for how they process your personal data',
      },
    ],
  },
  {
    title: 'Changes to our privacy policy',
    content: [
      {
        text: '72 Days keeps it privacy policy under regular review and places any updates on this webpage. This privacy policy was last updated on 30th March 2021',
      },
    ],
  },
  {
    title: 'How to contact us',
    content: [
      {
        text: 'If you have any questions about the 72 Days privacy policy, the data we hold for you, or you would like to exercise any of your data protection rights please contact us at hello@72days.dev',
        footnote:
          'You may also lodge a complaint with the Information Commissioner, or your local supervisory authority about any aspect of our handling or processing of your personal data. We would, however, appreciate the chance to address your concerns before you approach any supervisory authority, so please contact us in the first instance',
      },
    ],
  },
]

const PrivacyPolicyPage = () => {
  return (
    <div>
      <div className="px-4 pb-6">
        <div className="container">
          <div className="pt-2 pb-4">
            <MegaHeader>72 Days Privacy Policy</MegaHeader>
          </div>
          <div>
            {policySections.map(({ title, content }) => {
              return (
                <div className="px-2 pb-4">
                  <SubHeader>{title}</SubHeader>
                  {content.map(({ title, text, list, footnote }) => {
                    return (
                      <div className="pb-2 pt-1">
                        <Label>{title}</Label>
                        {!!text && (
                          <div className="pb-1">
                            <Text>{text}</Text>
                          </div>
                        )}
                        {!!list && (
                          <ul className="pl-2">
                            {list.map((item, index) => {
                              return (
                                <li key={index}>
                                  {'• '}
                                  {item}
                                </li>
                              )
                            })}
                          </ul>
                        )}
                        {!!footnote && (
                          <div className="pt-2">
                            <Text>{footnote}</Text>
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <Footer leftAlign />
    </div>
  )
}

export default PrivacyPolicyPage
