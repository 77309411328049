import React from 'react'

const sizes = {
  megaHeader: {
    fontSize: '60px',
    lineHeight: '64px',
  },
  pageHeader: {
    fontSize: '32px',
    lineHeight: '36px',
  },
}

const fonts = {
  phosphor: {
    fontWeight: 'normal',
    fontFamily: 'Phosphor',
  },
}
interface TypographyProps {
  size?: 'small' | 'medium' | 'large'
  style?: any
  className?: string
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
}

export const MegaHeader: React.FC<TypographyProps> = ({
  children,
  style = {},
  className = '',
  as = 'h1',
}) => {
  const element = React.createElement(as, {
    className,
    style: {
      fontSize: sizes.megaHeader.fontSize,
      lineHeight: sizes.megaHeader.lineHeight,
      marginTop: 10,
      marginBottom: 10,
      ...fonts.phosphor,
      ...style,
    },
    children,
  })
  return element
}

export const PageHeader: React.FC<TypographyProps> = ({
  children,
  style = {},
  className = '',
  as = 'h2',
}) => {
  const element = React.createElement(as, {
    className,
    style: {
      fontSize: sizes.pageHeader.fontSize,
      lineHeight: sizes.pageHeader.lineHeight,
      marginTop: 10,
      marginBottom: 10,
      fontWeight: 'bold',
      ...style,
    },
    children,
  })
  return element
}

export const Header: React.FC<TypographyProps> = ({
  children,
  style = {},
  className = '',
  as = 'h3',
}) => {
  const element = React.createElement(as, {
    className,
    style: {
      fontSize: 30,
      marginTop: 10,
      marginBottom: 10,
      lineHeight: '32px',
      ...style,
    },
    children,
  })
  return element
}

export const SubHeader: React.FC<TypographyProps> = ({
  children,
  style = {},
  className = '',
  as = 'h4',
}) => {
  const element = React.createElement(as, {
    className,
    style: {
      fontSize: 26,
      marginTop: 10,
      marginBottom: 10,
      lineHeight: '22px',
      ...style,
    },
    children,
  })
  return element
}

export const Label: React.FC<TypographyProps> = ({
  children,
  style = {},
  className = '',
}) => {
  return (
    <h4
      className={className}
      style={{
        fontSize: 20,
        marginTop: 10,
        marginBottom: 10,
        lineHeight: '22px',
        fontWeight: 'bold',
        ...style,
      }}
    >
      {children}
    </h4>
  )
}

export const Text: React.FC<TypographyProps> = ({
  children,
  style = {},
  className = '',
}) => {
  return (
    <span
      className={className}
      style={{ fontSize: '16px', lineHeight: '20px', ...style }}
    >
      {children}
    </span>
  )
}

export const TitleSmall: React.FC<TypographyProps> = ({
  children,
  style = {},
  className = '',
}) => {
  return (
    <h5
      className={className}
      style={{
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 'bold',
        marginBottom: 0,
        marginTop: 4,
        ...style,
      }}
    >
      {children}
    </h5>
  )
}
