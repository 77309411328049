import React from 'react'
import { graphql } from 'gatsby'
import { Element } from 'react-scroll'

import Layout from '../components/Layout'

import { Welcome } from '../components/page-components/Welcome'
import { WhoAreWe } from '../components/page-components/WhoAreWe'
import { WhyUs } from '../components/page-components/WhyUs'
import { ContactUs } from '../components/page-components/ContactUs'
import { OurWork } from '../components/page-components/OurWork'

const IndexPage = ({ data }) => {
  const { welcome, who_are_we, why_us, testimonials, ourWork, contactUs } = data
  return (
    <Layout>
      <>
        <Welcome {...welcome.frontmatter} />
        <Element name="who-are-we">
          <WhoAreWe {...who_are_we.frontmatter} />
        </Element>
        <Element name="why-us">
          <WhyUs
            {...why_us.frontmatter}
            testimonials={testimonials.edges.map(
              (edge) => edge.node.frontmatter
            )}
          />
        </Element>
        <Element name="our-work">
          <OurWork {...ourWork.frontmatter} />
        </Element>
        <Element name="get-in-touch">
          <ContactUs {...contactUs.frontmatter} hasDockedFooter />
        </Element>
      </>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    welcome: markdownRemark(frontmatter: { key: { eq: "welcome" } }) {
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 500
              quality: 100
              placeholder: TRACED_SVG
            )
          }
        }
        title
        subtitle
        strapline
      }
    }
    who_are_we: markdownRemark(frontmatter: { key: { eq: "who-are-we" } }) {
      frontmatter {
        title
        subtitle
        chrisImage {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 200
              quality: 100
              placeholder: TRACED_SVG
            )
          }
        }
        chrisTitle
        chrisBlurb
        chrisLinkedInProfileUrl
        russImage {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 200
              quality: 100
              placeholder: TRACED_SVG
            )
          }
        }
        russTitle
        russBlurb
        russLinkedInProfileUrl
        workedForTitle
        workedForSubtitle
      }
    }
    why_us: markdownRemark(frontmatter: { key: { eq: "why-us" } }) {
      frontmatter {
        title
        subtitle
        testimonialsTitle
        sections {
          title
          description
          image {
            alt
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 150
                  quality: 80
                  placeholder: TRACED_SVG
                )
              }
            }
          }
        }
      }
    }
    testimonials: allMarkdownRemark(
      limit: 10
      filter: { fileAbsolutePath: { regex: "/(testimonials)/" } }
    ) {
      edges {
        node {
          frontmatter {
            id
            priority
            name
            title
            comments
          }
        }
      }
    }
    ourWork: markdownRemark(frontmatter: { key: { eq: "our-work" } }) {
      frontmatter {
        title
        subtitle
        apps {
          pageLink
          isLinkExternal
          appName
          appIconImg {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                height: 100
                quality: 100
                placeholder: TRACED_SVG
              )
            }
          }
          description
          isIos
          isAndroid
        }
      }
    }
    contactUs: markdownRemark(frontmatter: { key: { eq: "contact-us" } }) {
      frontmatter {
        pageTitle
        pageIntro
        contactDetailsEmail
        contactFormTitle
        contactFormIntro
        contactFormAction
        bookCalendarTitle
        bookCalendarIntro
        bookCalendarUrl
        bookCalendarAction
      }
    }
  }
`
