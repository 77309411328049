import React from 'react'
import clsx from 'clsx'

import { MegaHeader, SubHeader, Text } from '../common/Typography'
import { Button } from '../common/Button'
import { AnalyticsAnchor } from '../../components/AnalyticsAnchor'

import ContactRequestForm from '../ContactRequestForm'
import Footer from '../Footer'

import './ContactUs.scss'

interface ContactUsProps {
  pageTitle: string
  pageIntro: string
  contactDetailsEmail: string
  contactFormTitle: string
  contactFormIntro: string
  contactFormAction: string
  bookCalendarTitle: string
  bookCalendarIntro: string
  bookCalendarUrl: string
  bookCalendarAction: string
  hasDockedFooter?: boolean
}

export const ContactUs = ({
  pageTitle,
  pageIntro,
  contactDetailsEmail,
  contactFormTitle,
  contactFormIntro,
  contactFormAction,
  bookCalendarTitle,
  bookCalendarIntro,
  bookCalendarUrl,
  bookCalendarAction,
  hasDockedFooter = false,
}: ContactUsProps) => {
  const containerClassName = clsx({
    ContactUs: true,
    'has-docked-footer': hasDockedFooter,
  })
  return (
    <div className={containerClassName}>
      <section className="section PrimarySection">
        <div className="container">
          <div className="content has-text-centered">
            <MegaHeader as="h2">{pageTitle}</MegaHeader>
            <AnalyticsAnchor id="ContactUs" />
            <Text>{pageIntro}</Text>
            <div className="is-size-5 mt-5">
              <a href={`mailto:${contactDetailsEmail}`}>
                <Button size="large" cta="email" title={contactDetailsEmail} />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="content">
          <div className="columns">
            <div className="column is-6 ContactForm">
              <SubHeader>{contactFormTitle}</SubHeader>
              <Text>{contactFormIntro}</Text>
              <div className="mt-4">
                <ContactRequestForm
                  successUrl="/contact/thanks/"
                  actionText={contactFormAction}
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="divider" />
            </div>

            <div className="column is-6 BookCalendar">
              <SubHeader>{bookCalendarTitle}</SubHeader>
              <Text>{bookCalendarIntro}</Text>
              <div className="mt-4">
                <a href={bookCalendarUrl} target="_blank" rel="noopener">
                  <Button
                    size="large"
                    cta="calendly"
                    title={bookCalendarAction}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {hasDockedFooter && <Footer docked />}
    </div>
  )
}
