import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/Layout'
import { MegaHeader, Text } from '../components/common/Typography'

const NotFoundPage = () => {
  return (
    <Layout>
      <section className="section">
        <div className="container">
          <div className="content has-text-centered">
            <MegaHeader>NOT FOUND</MegaHeader>
            <Text>
              Nothing to see here. Maybe one of our links is broken. Sorry!
            </Text>
            <div className="mt-3">
              <Link to="/">Back to safety.</Link>
            </div>
            <div className="mt-6">
              <StaticImage
                layout="fixed"
                placeholder="tracedSVG"
                src="../../static/img/small_monster.png"
                alt="Small mischievous monster."
                height={96}
                width={96}
                style={{ margin: 'auto' }}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
