import React from 'react'

import { useContactForm } from '../hooks/useContactForm'
import { Button } from './common/Button'

interface ContactRequestFormProps {
  successUrl: string
  actionIntro?: string
  actionText?: string
}

export default ({
  successUrl = '/contact/thanks/',
  actionIntro = '',
  actionText = 'Submit',
}: ContactRequestFormProps) => {
  const {
    disclaimerAccepted,
    isSubmitting,
    error,
    onChangeField,
    onSubmit,
    onClearError,
    onToggleDisclaimer,
  } = useContactForm()

  return (
    <div className="content">
      {!!error && (
        <div className="notification is-warning">
          <button className="delete" onClick={onClearError} />
          {error.message || 'Error sending contact request.'}
        </div>
      )}
      <form
        name="contact-request"
        method="post"
        action={successUrl}
        onSubmit={onSubmit}
      >
        <div className="field">
          <label className="label" htmlFor={'name'}>
            Your name
          </label>
          <div className="control">
            <input
              className="input"
              type={'text'}
              name={'name'}
              onChange={onChangeField}
              id={'name'}
              required={true}
            />
          </div>
        </div>
        <div className="field">
          <label className="label" htmlFor={'email'}>
            Email
          </label>
          <div className="control">
            <input
              className="input"
              type={'email'}
              name={'email'}
              onChange={onChangeField}
              id={'email'}
              required={true}
            />
          </div>
        </div>
        <div>
          {!!actionIntro && <label>{actionIntro}</label>}
          <label className="checkbox">
            <input type="checkbox" onChange={onToggleDisclaimer} /> I agree for
            my data to be handled according to the 72 Days{' '}
            <a href="/privacy-policy" target="_blank">
              privacy policy
            </a>
          </label>
          <div className="mt-3">
            <Button
              size="large"
              cta="send"
              isSubmit
              isDisabled={isSubmitting || !disclaimerAccepted}
              title={isSubmitting ? '...' : actionText}
              fullWidthMobile
            />
          </div>
        </div>
      </form>
    </div>
  )
}
