import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../../components/Layout'
import { MegaHeader, Text } from '../../components/common/Typography'

export default () => {
  return (
    <Layout>
      <section className="section">
        <div className="container">
          <div className="content has-text-centered">
            <MegaHeader>Thank you!</MegaHeader>
            <Text>
              Either Chris or Russ will be in touch soon, we look forward to
              speaking with you!
            </Text>
            <div className="mt-3">
              <Link to="/">Back</Link>
            </div>
            <div className="mt-6">
              <StaticImage
                layout="fixed"
                placeholder="tracedSVG"
                src="../../../static/img/large_monster.png"
                alt="Large friendly monster."
                style={{ margin: 'auto' }}
                height={128}
                width={128}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
