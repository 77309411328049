import React, { useState, useMemo, useCallback } from 'react'
import { Link } from 'gatsby'
import Slider from 'react-input-slider'

import Tache1 from '../../img/taches/tache-1.svg'
import Tache2 from '../../img/taches/tache-2.svg'

import { MegaHeader, Text } from '../common/Typography'
import { SuperHeroDisplay } from '../SuperHeroDisplay'
import PreviewCompatibleImage from '../PreviewCompatibleImage'
import { Button } from '../common/Button'
import { PageHeader } from '../common/Typography'

import './Movember.scss'

type MoMember = {
  name: string
  message: string
  imageChar: any
  imageReal: any
}

interface MovemberProps {
  pageTitle: string
  members: Array<MoMember>
  donationLink: string
  donationTitle: string
  donationSubTitle: string
  qrCodeImage: any
}

type ImageTypes = 'russSvg' | 'russReal' | 'chrisSvg' | 'chrisReal'
const centreOffsets: {
  [key in ImageTypes]: { top: number; left: number; rotate: number }
} = {
  russSvg: {
    top: 106,
    left: 132,
    rotate: 10,
  },
  russReal: {
    top: 134,
    left: 134,
    rotate: 8,
  },
  chrisSvg: {
    top: 100,
    left: 136,
    rotate: 0,
  },
  chrisReal: {
    top: 118,
    left: 130,
    rotate: -10,
  },
}

const MemberMo = ({ name, message, imageReal, imageChar }: MoMember) => {
  const [useRealImage, setUseRealImage] = useState(true)
  const [showTache, setShowTache] = useState(true)
  const [moSliderValue, setMoSliderValue] = useState(0.25)

  const { tacheWidth, tacheHeight } = useMemo(() => {
    const minImageHeight = 5
    const minImageWidth = 10
    const maxImageHeight = 50
    const maxImageWidth = 100
    return {
      tacheHeight: Math.max(
        (minImageHeight + maxImageHeight) * moSliderValue,
        minImageHeight
      ),
      tacheWidth: Math.max(
        (minImageWidth + maxImageWidth) * moSliderValue,
        minImageWidth
      ),
    }
  }, [moSliderValue])

  const getOffsets = useCallback(
    (
      imageType: ImageTypes
    ): { offsetTop: number; offsetLeft: number; rotate: number } => {
      const defaultOffsets = centreOffsets[imageType]
      return {
        offsetTop: defaultOffsets.top - tacheWidth / 2,
        offsetLeft: defaultOffsets.left - tacheWidth / 2,
        rotate: defaultOffsets.rotate,
      }
    },
    [tacheWidth, tacheHeight]
  )

  const Tache = name === 'Chris' ? Tache1 : Tache2
  const imageType = `${name.toLowerCase()}${
    useRealImage ? 'Real' : 'Svg'
  }` as ImageTypes
  const { offsetTop, offsetLeft, rotate } = getOffsets(imageType)

  return (
    <div className="mo-member" key={name} style={{ position: 'relative' }}>
      <div>
        {showTache && (
          <img
            src={Tache}
            width={tacheWidth}
            height={tacheHeight}
            alt="Fake Mustache"
            style={{
              position: 'absolute',
              top: offsetTop,
              left: offsetLeft,
              zIndex: 2,
              transform: `rotate(${rotate}deg)`,
            }}
          />
        )}
        <SuperHeroDisplay
          name={name}
          blurb={message}
          image={useRealImage ? imageReal : imageChar}
        />
      </div>
      <div className="mo-controls">
        <div className="mb-2">
          <Button
            type="secondary"
            size="small"
            onPress={() => setShowTache(!showTache)}
            title={showTache ? 'No Mo' : 'Mo'}
          />
        </div>
        <div style={{ height: 30 }}>
          <Slider
            axis="x"
            xmin={0}
            xmax={1}
            xstep={0.01}
            x={moSliderValue}
            disabled={!showTache}
            styles={{
              active: {
                backgroundColor: '#50d07b',
              },
            }}
            onChange={({ x }) => setMoSliderValue(x)}
          />
        </div>
      </div>
    </div>
  )
}

export const Movember = ({
  pageTitle,
  donationLink,
  donationTitle,
  donationSubTitle,
  qrCodeImage,
  members,
}: MovemberProps) => {
  const memberRuss = members[0]
  const memberChris = members[1]
  return (
    <div className="Movember">
      <section className="section section-intro">
        <div className="container">
          <div className="content has-text-centered">
            <MegaHeader>{pageTitle}</MegaHeader>
            <div className="mt-3 mb-3">
              <Link to="/">Back to Homepage</Link>
            </div>
            <div className="mb-4">
              <div className="mo-members">
                <MemberMo {...memberRuss} />
                <MemberMo {...memberChris} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section section-donate">
        <div className="container">
          <div className="content has-text-centered">
            <div className="mb-4">
              <PageHeader>{donationTitle}</PageHeader>
              <Text>{donationSubTitle}</Text>
            </div>
            <div>
              <div className="mb-4">
                <a href={donationLink} target="_blank" rel="noopener">
                  <Button type="primary" size="large" title="Donate" />
                </a>
              </div>
              <div className="qr-code">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: qrCodeImage,
                    alt: 'QR Code for donation link.',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
