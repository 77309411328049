import React from 'react'

import './WhyUs.scss'

import { Header, MegaHeader, SubHeader, Text } from '../common/Typography'
import { Testimonials, TestimonialItem } from '../Testimonials'
import PreviewCompatibleImage from '../PreviewCompatibleImage'
import { AnalyticsAnchor } from '../../components/AnalyticsAnchor'

interface ContentSection {
  title: string
  description: string
  image: any
}

interface WhyUsProps {
  title: string
  subtitle: string
  testimonialsTitle: string
  sections: ContentSection[]
  testimonials: Array<TestimonialItem>
}

export const WhyUs = ({
  title,
  subtitle,
  testimonialsTitle,
  sections,
  testimonials,
}: WhyUsProps) => {
  return (
    <div className="WhyUs">
      <section className="section">
        <div className="content">
          <div className="has-text-centered">
            <MegaHeader as="h2">{title}</MegaHeader>
            <Text>{subtitle}</Text>
          </div>
        </div>
        <AnalyticsAnchor id="WhyUs" />
      </section>
      <section className="section WhyUsExplainer">
        <div className="content">
          {sections.map((section, index) => {
            return (
              <div key={index} className="ContentSection">
                <div className="ContentSectionText">
                  <SubHeader>{section.title}</SubHeader>
                  <Text>{section.description}</Text>
                </div>
                <div className="ContentSectionImage">
                  <PreviewCompatibleImage
                    imageInfo={section.image}
                    style={{ borderRadius: 4 }}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </section>
      <section className="section Testimonials">
        <div className="content">
          <div className="has-text-centered title">
            <Header>{testimonialsTitle}</Header>
            <AnalyticsAnchor id="Testimonials" />
          </div>
          <Testimonials testimonials={testimonials} />
        </div>
      </section>
    </div>
  )
}
