import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import { ContactUs } from '../../components/page-components/ContactUs'

export default ({ data }) => {
  const { contactUs } = data
  return (
    <Layout>
      <ContactUs {...contactUs.frontmatter} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ContactUsPageTemplate {
    contactUs: markdownRemark(frontmatter: { key: { eq: "contact-us" } }) {
      frontmatter {
        pageTitle
        pageIntro
        contactDetailsEmail
        contactFormTitle
        contactFormIntro
        contactFormAction
        bookCalendarTitle
        bookCalendarIntro
        bookCalendarUrl
        bookCalendarAction
      }
    }
  }
`
