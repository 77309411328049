import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { PageHeader, SubHeader, Text } from '../common/Typography'
import PreviewCompatibleImage from '../PreviewCompatibleImage'
import { AppStoreLinks } from '../AppStoreLinks'

import './BetterDrivingTheory.scss'
import { SwipeableSlides } from '../common/SwipeableSlides'

type BetterDrivingTheoryProps = {
  title: string
  description: string
  screenshots: Array<{
    id: string
    image: {
      image: any
      alt: string
    }
  }>
  betterFeatures: string[]
  smallPrint: string[]
  termsLink: string
  privacyLink: string
}

const fontFamily = 'Poppins'
const appStoreLink =
  'https://apps.apple.com/gb/app/better-driving-theory/id1608720630'
const playStoreLink =
  'https://play.google.com/store/apps/details?id=dev.seventytwodays.drivingtheory'

export const BetterDrivingTheory = ({
  title,
  description,
  screenshots,
  betterFeatures,
  smallPrint,
  termsLink,
  privacyLink,
}: BetterDrivingTheoryProps) => {
  return (
    <div className="BetterDrivingTheory">
      <section className="section section-hero">
        <div className="section-content">
          <div>
            <StaticImage
              src="../../../static/img/BetterDrivingLogo.png"
              alt="Better Driving Theory app logo"
              layout="constrained"
              width={200}
              style={{
                borderRadius: 20,
              }}
            />
          </div>
          <div>
            <PageHeader style={{ fontFamily, fontWeight: 900 }}>
              {title}
            </PageHeader>
            <Text>{description}</Text>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="columns">
          <div className="column is-6 has-text-left">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <div>
                <SubHeader>What makes us Better?</SubHeader>
                <ul>
                  {betterFeatures.map((f) => (
                    <li key={f}>{f}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="column is-6">
            <div className="SlidesScreenshotsContainer">
              <SwipeableSlides
                children={screenshots.map((s) => (
                  <div key={s.image.alt}>
                    <PreviewCompatibleImage
                      imageInfo={s.image}
                      loading="eager"
                      style={{ borderRadius: 20 }}
                    />
                  </div>
                ))}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section alt">
        <div className="section-content">
          <div>
            <SubHeader>Available on iOS and Android.</SubHeader>
            <Text>Free to download and evaluate.</Text>
          </div>
          <AppStoreLinks
            appStoreLink={appStoreLink}
            playStoreLink={playStoreLink}
          />
        </div>
      </section>

      <section className="section">
        <div className="section-content">
          {smallPrint.map((s, index) => {
            return (
              <p key={index}>
                <small>{s}</small>
              </p>
            )
          })}
          <div className="terms-links">
            <a href={privacyLink}>Privacy Policy</a>
            <a href={termsLink}>Terms of Use</a>
          </div>
        </div>
      </section>
    </div>
  )
}
