import React, { useCallback, useState } from 'react'
import clsx from 'clsx'
import SwipeableViews from 'react-swipeable-views'

import './SwipeableSlides.scss'

interface SwipeableSlidesProps {
  children: Array<JSX.Element>
  initialIndex?: number
}

export const SwipeableSlides = ({
  initialIndex = 0,
  children,
  ...props
}: SwipeableSlidesProps) => {
  const [activeItemIndex, setActiveItem] = useState(initialIndex)

  const onChangeIndex = useCallback(
    (index) => {
      setActiveItem(index)
    },
    [setActiveItem]
  )

  return (
    <div className="SwipeableSlides">
      <SwipeableViews
        enableMouseEvents
        onChangeIndex={onChangeIndex}
        index={activeItemIndex}
        {...props}
      >
        {children.map((item, index) => {
          const classNames = clsx('SwipeableSlide', {
            'is-active': index === activeItemIndex,
          })
          return (
            <div key={index} className={classNames}>
              {item}
            </div>
          )
        })}
      </SwipeableViews>
      <div className="ItemIndicators">
        {children.map((_, index) => {
          const classNames = clsx('ItemIndicator', {
            'is-active': index === activeItemIndex,
          })
          return (
            <div
              key={index}
              className={classNames}
              onClick={() => setActiveItem(index)}
            />
          )
        })}
      </div>
    </div>
  )
}
