import React from 'react'
import { Link as ScrollLink } from 'react-scroll'

import { MegaHeader, Header, Text } from '../common/Typography'
import { Button } from '../common/Button'
import PreviewCompatibleImage from '../PreviewCompatibleImage'
import { MovemberAnnouncement } from '../MovemberAnnouncement'
import './Welcome.scss'

const showMoInfo = false

interface WelcomeProps {
  title: string
  subtitle: string
  strapline: string
  image: any
}

export const Welcome = ({
  title,
  subtitle,
  strapline,
  image,
}: WelcomeProps) => {
  const imgStyle = { maxWidth: 500 }

  return (
    <div
      className="Welcome"
      style={{
        margin: 'auto',
      }}
    >
      <section className="section">
        <div
          className="is-flex-tablet"
          style={{
            minHeight: '80vh',
          }}
        >
          <div
            className="content-block"
            style={{
              flex: 1,
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <MegaHeader>{title}</MegaHeader>
            <Header>{subtitle}</Header>
            <Text>{strapline}</Text>
            <div
              className="action-buttons mt-5 is-flex-tablet"
              style={{ flexDirection: 'row' }}
            >
              <ScrollLink
                to="who-are-we"
                smooth={true}
                duration={500}
                offset={20}
              >
                <Button title="Learn More" type="primary" size="large" />
              </ScrollLink>
              <ScrollLink
                to="get-in-touch"
                smooth={true}
                duration={500}
                // offset={-20}
              >
                <Button
                  type="primary"
                  title="Get In Touch"
                  size="large"
                  outline
                />
              </ScrollLink>
            </div>
          </div>
          <div
            className="image-block"
            style={{
              flex: 1,
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <PreviewCompatibleImage
              style={imgStyle}
              imageInfo={{ image, alt: 'Chris and Russ.' }}
            />
            {showMoInfo && (
              <div className="mt-2">
                <MovemberAnnouncement />
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  )
}
