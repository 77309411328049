export const navLinks = [
  {
    path: '/app-cost',
    label: 'Pricing',
  },
  {
    path: '/contact',
    label: 'Contact',
  },
]

export const IS_NAVBAR_FIXED_TOP = false
