import React from 'react'
import { GatsbyImage, getImage, GatsbyImageProps } from 'gatsby-plugin-image'

interface PreviewCompatibleImageProps
  extends Omit<GatsbyImageProps, 'image' | 'imgStyle' | 'alt'> {
  imageInfo: {
    alt?: string
    image: any | null
  }
  style?: any
}

const PreviewCompatibleImage = ({
  imageInfo,
  style,
  ...props
}: PreviewCompatibleImageProps) => {
  const { alt = '' } = imageInfo
  const image = getImage(imageInfo.image)

  if (!!image) {
    return <GatsbyImage image={image} alt={alt} imgStyle={style} {...props} />
  }

  if (!!imageInfo.image)
    return <img src={imageInfo.image} alt={alt} style={style} {...props} />

  return null
}

export default PreviewCompatibleImage
